import React, { useEffect, useState } from 'react';
import { Checkbox, Dropdown } from 'antd';
import type { CheckboxProps, MenuProps } from 'antd';
import { useSearchParams } from "react-router-dom";
import { DropdownButtonType } from 'antd/es/dropdown';

type MenuItem = Required<MenuProps>['items'][number];

interface Props {
    filter: string;
    filterItems: string[] | number[];
}

interface CheckedProps {
    item: string;
    checked: boolean;
}

function getItem(
    label: React.ReactNode,
    key: React.Key,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        children,
        label,
    } as MenuItem;
}

export function FilterButton({ filter, filterItems }: Props) {
    const [isChecked, setChecked] = useState<CheckedProps[]>(
        filterItems.map((entry: any) => ({ item: entry, checked: window?.location?.search?.includes(entry?.replace(/ /g,"+")) }))
    );
    const [type, setType] = useState<DropdownButtonType>(isChecked.some(entry => entry?.checked)? "primary" : "dashed");
    const [text, setText] = useState(filter);
    const [searchParams, setSearchParams] = useSearchParams();

    const updateChecked = (item: string, checked: boolean) => {
        setChecked(prevState => prevState.map((entry) => (entry.item === item ? { ...entry, checked } : entry)));
    };

    const onChange: CheckboxProps['onChange'] = (e: any) => {
        const itemName = e.target.name;
        const itemChecked = e.target.checked;
        updateChecked(itemName, itemChecked);
    
        // Immediately update isChecked state to reflect the new changes
        const updatedIsChecked = isChecked.map((entry) => 
            entry.item === itemName ? { ...entry, checked: itemChecked } : entry
        );
    
        // Update searchParams accordingly
        setSearchParams((params) => {
            if (itemChecked) {
                params.append(filter, itemName);
            } else {
                const currentValues = params.getAll(filter).filter(value => value !== itemName);
                params.delete(filter);
                currentValues.forEach(value => params.append(filter, value));
            }
            return params;
        });
    
        // Check if there's at least one filter checked after the current update
        const anyCheckedAfterUpdate = updatedIsChecked.some(entry => entry.checked);
        setType(anyCheckedAfterUpdate ? "primary" : "dashed");
    };
    
    

    useEffect(() => {
        const checkedItems = isChecked.filter(item => item.checked).map(item => item.item);
        if (checkedItems.length === 1) {
            setText(`${filter}: ${checkedItems[0]}`);
        } else if (checkedItems.length > 1) {
            setText(`${filter}: Multiple`);
        } else {
            setText(filter);
            setType("dashed");
        }
    }, [isChecked, filter]);

    const items = filterItems.map((item) => getItem(<Checkbox onChange={onChange} name={`${item}`} checked={isChecked.find(x => x.item === item)?.checked}>{item}</Checkbox>, item));

    return (
        <Dropdown.Button
            size="small"
            type={type}
            menu={{ items }}
            trigger={['click']}
            onClick={() => {
                if (type === "primary") {
                    setSearchParams(params => {
                        params.delete(filter);
                        return params;
                    });
                    setChecked(isChecked.map(item => ({ ...item, checked: false })));
                    setType("dashed");
                    setText(filter);
                }
            }}
            placement="bottomLeft"
            overlayStyle={{ position: "fixed" }}
        >
            {text}
        </Dropdown.Button>
    );
}
