import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { Input, Layout, Typography, List, Col, Row, DatePicker, ConfigProvider, Select, Card, Space, Tag, Collapse, CollapseProps, Divider, SelectProps, Flex } from 'antd';
const { TextArea } = Input;
const { Header, Content } = Layout;
const { Title, Text } = Typography;

import { getAganistType, getEmployees, getSourceType, search } from '../../../service/httpsCalls'
import "./search.scss";
import Sider from 'antd/es/layout/Sider';
import dayjs from 'dayjs';
import { RedoOutlined } from '@ant-design/icons';
import { DateRange } from '../../../types';
import debounce from 'lodash.debounce';
interface SearchParams {
    searchText: string,
    searchType: string,
    pageIndex: any,
    pageSize: any,
    statusId: any,
    assignedTo: any,
    complaintDateFrom: any,
    complaintDateTo: any,
    typeId: any,
    againstTypeId: any,
    sourceTypeId: any,
}

export default function Search() {

    const [searchResult, setSearchResult] = useState([])
    const [filteredSearchResult, setFilteredSearchResult] = useState([])
    const [searchError, setSearchError] = useState(false)
    const [searchItem, setSearchItem] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchTypeValue, setSearchTypeValue] = useState('all')
    const [notFound, setNotFound] = useState(false)
    const [dateRange, setDateRange] = useState<DateRange>({ start: null, end: null });

    const { RangePicker } = DatePicker;
    const searchTypeOptions = [
        { label: 'All', value: 'all' },
        { label: 'Case Number', value: 'case-number' },
        { label: 'Trip Number', value: 'trip-number' },
        { label: 'Member Id', value: 'member-id' },
        { label: 'Member Name', value: 'member-name' },
    ];

    const listRef = useRef<HTMLDivElement>(null);
    const [pageSize, setPageSize] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [startDate, setStartDate] = useState<string | any>('');
    const [endDate, setEndDate] = useState<string | any>('');
    const [typeFilter, setTypeFilter] = useState(0);
    const [statusFilter, setStatusFilter] = useState(0);
    const [assignedFilter, setAssignedFilter] = useState(0);
    const [assignedOptions, setAssignedOptions] = useState<SelectProps['options']>([{ value: 0, label: ' ' }]);
    const baseUrl = window.localStorage.getItem("Referrer");
    const [aganistTypeFilter, setAganistTypeFilter] = useState(0);
    const [sourceTypeFilter, setSourceTypeFilter] = useState(0)
    const [assignedTypeOption, setAssignedTypeOptions] = useState<SelectProps['options']>([{ value: 0, label: ' ' }]);
    const [sourceTypeOption, setSourceTypeOption] = useState<SelectProps['options']>([{ value: 0, label: ' ' }]);

    const useDebounce = (callback: any) => {
        const ref = useRef<any>();

        useEffect(() => {
            ref.current = callback;
        }, [callback]);

        const debouncedCallback = useMemo(() => {
            const func = () => {
                ref.current?.();
            };

            return debounce(func, 1000);
        }, []);

        return debouncedCallback;
    };

    const debouncedSearch = useDebounce(() => {
        let data = {
            searchText: searchItem,
            searchType: searchTypeValue,
            pageIndex: currentPage,
            pageSize: pageSize,
            statusId: statusFilter == 0 ? null : statusFilter,
            assignedTo: assignedFilter == 0 ? null : assignedFilter,
            complaintDateFrom: startDate?.length === 0 ? null : dayjs(startDate).format('YYYY-MM-DDT00:00:00'),
            complaintDateTo: endDate?.length === 0 ? null : dayjs(endDate).format('YYYY-MM-DDT23:59:59'),
            typeId: typeFilter == 0 ? null : typeFilter,
            againstTypeId: aganistTypeFilter == 0 ? null : aganistTypeFilter,
            sourceTypeId: sourceTypeFilter == 0 ? null : sourceTypeFilter,
        }
        executeSearch(data);
    });

    // Extract the API call logic into a function
    const executeSearch = ({ searchText, searchType, pageIndex, pageSize, statusId, assignedTo, complaintDateFrom, complaintDateTo, typeId, againstTypeId, sourceTypeId }: SearchParams) => {

        setLoading(true);
        setNotFound(false);
        console.log("executed")
        search({
            searchText,
            searchType,
            pageIndex,
            pageSize,
            statusId,
            assignedTo,
            complaintDateFrom,
            complaintDateTo,
            typeId,
            againstTypeId,
            sourceTypeId,
        }, (res: any) => {
            if (res !== 'error') {
                setSearchResult(res.items)
                setFilteredSearchResult(res.items)
                if (res.length == 0) setNotFound(true)
                setSearchError(false)
                setLoading(false)
                setTotalPages(Math.ceil(res.totalRecords / (pageSize / pageSize)));
            } else {
                setSearchError(true)
                setLoading(false)
                setSearchResult([])
                setFilteredSearchResult([])
            }
        });
    };


    function getNextPage(p: any) {
        setLoading(true)
        setNotFound(false)
        let data = {
            searchText: searchItem,
            searchType: searchTypeValue,
            pageIndex: p,
            pageSize: pageSize,
            statusId: statusFilter == 0 ? null : statusFilter,
            assignedTo: assignedFilter == 0 ? null : assignedFilter,
            complaintDateFrom: startDate?.length === 0 ? null : dayjs(startDate).format('YYYY-MM-DDT00:00:00'),
            complaintDateTo: endDate?.length === 0 ? null : dayjs(endDate).format('YYYY-MM-DDT23:59:59'),
            typeId: typeFilter == 0 ? null : typeFilter,
            againstTypeId: aganistTypeFilter == 0 ? null : aganistTypeFilter,
            sourceTypeId: sourceTypeFilter == 0 ? null : sourceTypeFilter,
        }
        executeSearch(data);
        //search(data, (res: any) => {
        //    if (res !== 'error') {
        //        setSearchResult(res.items)
        //        setFilteredSearchResult(res.items)
        //        if (res.length == 0) setNotFound(true)
        //        setSearchError(false)
        //        setLoading(false)
        //        setTotalPages(Math.ceil(res.totalRecords / (pageSize / pageSize)));
        //    } else {
        //        setSearchError(true)
        //        setLoading(false)
        //        setSearchResult([])
        //        setFilteredSearchResult([])
        //    }
        //})
    }

    function handleSearch(e: any) {
        const searchText = e.key !== "Enter" ? e.target.value : searchItem;
        setSearchItem(e.target.value);
        debouncedSearch();
    }


    function dateRangeFilter(start: any, end: any) {

        const startDate_ = new Date(start);
        const endDate_ = new Date(end);

        let filter = searchResult.filter((item: any, index) => {
            const complaintDate = new Date(item.complaintDate);
            return complaintDate >= startDate_ && complaintDate <= endDate_;
        })

        setFilteredSearchResult(filter)

    }

    useEffect(() => {

        if ((startDate !== "" && endDate !== "") && (new Date(endDate) > new Date(startDate))) {
            dateRangeFilter(startDate, endDate)
            getNextPage(currentPage);
        }
    }, [endDate, startDate])

    function onChangeSearchType(value: string) {
        setSearchTypeValue(value)
    }

    function handleNavigation(id: string) {

        window.location.href = baseUrl + `/Reservation/Reservation/EditComplaint?complaintInfoId=${id}`;
    }


    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Complaint Date',
            children: <RangePicker
                allowClear={false}
                format={["MM/DD/YYYY"]}
                onChange={(dates: any) => {
                    if (dates) {
                        setStartDate(dates[0]);
                        setEndDate(dates[1]);
                        setCurrentPage(0)
                    }
                }}
                value={[startDate, endDate]}
            />,
        },
        {
            key: '2',
            label: "Type",
            children: <Select
                allowClear
                style={{ width: "100%" }}
                value={typeFilter}
                options={[
                    { value: 0, label: " " },
                    { value: 1, label: "Internal Inquiry" },
                    { value: 2, label: "Standard" },
                    { value: 3, label: "DMHC" },
                    { value: 4, label: "Dissatisfaction" },
                    { value: 5, label: "Urgent" }
                ]}
                onChange={(v, l) => { setTypeFilter(v), setCurrentPage(0) }}
            />
        },
        {
            key: '3',
            label: "Status",
            children: <Select
                allowClear
                style={{ width: "100%" }}
                options={[
                    { value: 0, label: " " },
                    { value: 1, label: "Open" },
                    { value: 3, label: "Substantiated" },
                    { value: 4, label: "Unsubstantiated" },
                    { value: 5, label: "Indeterminable" },
                    { value: 7, label: "Pending" },
                    { value: 8, label: "In Progress" },
                    { value: 9, label: "Internal Review" },
                    { value: 10, label: "Client Review" },
                    { value: 11, label: "Resolved" },
                    { value: 12, label: "Client Denied" },
                ]}
                onChange={(v, l) => { setStatusFilter(v), setCurrentPage(0) }}
                value={statusFilter}
            />
        },
        {
            key: '4',
            label: "Assigned To",
            children: <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                value={assignedFilter}
                options={assignedOptions}
                onChange={(v, l) => { setAssignedFilter(v), setCurrentPage(0) }}
                onSearch={(text) => getPanelValue(text)}

            />
        },
        {
            key: '5',
            label: 'Against Type',
            children: <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                options={assignedTypeOption}
                value={aganistTypeFilter}
                onChange={(value) => { setAganistTypeFilter(value), setCurrentPage(0) }}
                onSearch={() => getAganistTypeDropdownValue()}
            />
        },
        {
            key: '6',
            label: "Source Type",
            children: <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                options={sourceTypeOption}
                value={sourceTypeFilter}
                onChange={(value) => { setSourceTypeFilter(value), setCurrentPage(0) }}
                onSearch={() => getSourceTypeDropdownValue()}
            />
        }

    ];

    // AganistType dropdown functionality 
    const getAganistTypeDropdownValue = async () => {
        var res = await getAganistType()
        const temp = res?.map((v: any) => {
            return {
                value: v?.id,
                label: v?.name,
                ...v
            }
        })
        let newArray = [...temp];
        newArray.unshift({ value: 0, label: ' ' })
        setAssignedTypeOptions(newArray)
    }

    // SourceType dropdown functionality
    const getSourceTypeDropdownValue = async () => {
        var res = await getSourceType()
        const temp = res?.map((v: any) => {
            return {
                value: v?.id,
                label: v?.name,
                ...v
            }
        })
        let newArray = [...temp];
        newArray.unshift({ value: 0, label: ' ' })
        setSourceTypeOption(newArray)
    }


    useEffect(() => {
        if (listRef.current) {
            const newPageSize = Math.floor(listRef.current.clientHeight / 172);
            setPageSize(newPageSize);
        }
        getPanelValue('');
        getAganistTypeDropdownValue();
        getSourceTypeDropdownValue();

    }, []);

    useEffect(() => {
        if (pageSize != 0)
            getNextPage(currentPage);
    }, [searchTypeValue, pageSize, typeFilter, assignedFilter, statusFilter, aganistTypeFilter, sourceTypeFilter]);




    function onPageChange(p: any, ps: any) {

        setCurrentPage(p - 1);
        getNextPage(p - 1);
        setPageSize(ps);
    }

    const getPanelValue = async (searchText: string) => {
        var res = await getEmployees(searchText);
        const temp = res?.items?.map((e: any) => {
            return renderItem(e);
        })
        let newArray = [...temp]
        newArray.unshift({ value: 0, label: ' ' });
        setAssignedOptions(newArray);
    }
    const renderItem = (employee: any) => ({
        value: employee.id,
        label: employee.fullName,
        ...employee
    });

    function resetFilters(e: any): void {

        setEndDate('')
        setStartDate('')
        setStatusFilter(0)
        setAssignedFilter(0)
        setTypeFilter(0)
        setAganistTypeFilter(0)
        setSourceTypeFilter(0)



        let data = {
            searchText: searchItem,
            searchType: searchTypeValue,
            pageIndex: currentPage,
            pageSize: pageSize,
            statusId: null,
            assignedTo: null,
            complaintDateFrom: null,
            complaintDateTo: null,
            typeId: null,
            againstTypeId: null,
            sourceTypeId: null
        }
        search(data, (res: any) => {
            if (res !== 'error') {
                setSearchResult(res.items)
                setFilteredSearchResult(res.items)
                if (res.length == 0) setNotFound(true)
                setSearchError(false)
                setLoading(false)
                setTotalPages(Math.ceil(res.totalRecords / pageSize));
            } else {
                setSearchError(true)
                setLoading(false)
                setSearchResult([])
                setFilteredSearchResult([])
            }
        })

    }


    function ifToShowResetFilter(): boolean {

        if (startDate == "" && endDate == "" && typeFilter == 0 && statusFilter == 0 && assignedFilter == 0 && aganistTypeFilter == 0 && sourceTypeFilter == 0) {
            return false
        }

        return true
    }

    return <ConfigProvider theme={{
        components: {
            Layout: { headerBg: "#fff", bodyBg: "#eff1f5", siderBg: "#fff" }
        }
    }} >
        <Layout style={{ borderWidth: 0, borderTopWidth: 1, borderColor: "#d9d9d9", borderStyle: "solid", height: "100%" }}>
            <Header style={{ width: "100%", borderWidth: 0, borderBottomWidth: 1, borderColor: "#d9d9d9", borderStyle: "solid", height: "fit-content", padding: "2rem", position: "sticky", top: 0 }}>
                <Flex>
                    <Input
                        onChange={handleSearch}
                        size={"large"}
                        allowClear
                        addonBefore={
                            <Select
                                style={{ width: "200px" }}
                                options={searchTypeOptions}
                                onSelect={onChangeSearchType}
                                value={searchTypeValue} />}
                    />
                </Flex>
            </Header>

            <Layout style={{ minHeight: "calc(100vh - 165px)" }}>
                <Sider width="20%" style={{ borderWidth: 0, borderRightWidth: 1, borderColor: "#d9d9d9", borderStyle: "solid", overflowY: "auto", height: "calc(100vh - 164px)" }}>
                    <Flex vertical={true} style={{ height: "100%" }}>
                        
                        <Collapse
                            items={items}
                            defaultActiveKey={['1']}
                            bordered={false}
                            style={{ borderWidth: 0, borderRadius: 0, backgroundColor: 'transparent' }}
                        />
                        {
                            ifToShowResetFilter() &&
                            <div className='reset-filters' onClick={resetFilters}>
                                <span><RedoOutlined /> Reset Filters</span>
                            </div>}
                    </Flex >
                </Sider>
                <Content
                    ref={listRef}
                >
                    <List
                        style={{ overflowY: "auto", height: "calc(100vh - 164px)" }}
                        itemLayout="vertical"
                        split={false}
                        dataSource={filteredSearchResult}
                        pagination={{ current: currentPage + 1, pageSize: pageSize, align: 'center', onChange: onPageChange, total: totalPages, showSizeChanger: true, }}

                        renderItem={(item: any) => {
                            return <List.Item style={{ padding: "1rem" }}>
                                <Card
                                    title={!loading &&
                                        <div>
                                            <div className="header-text" onClick={e => handleNavigation(item.id)}>
                                                {item.memberFullName}

                                            </div>
                                            <div className="small-text">{item?.tripInfo}</div>
                                        </div>}
                                    extra={!loading && <Tag style={{ marginRight: 0 }} color="blue">{item.statusName}</Tag>}
                                    loading={loading}
                                    styles={{ body: { padding: "1.5rem", paddingTop: "1rem", paddingBottom: "1rem" } }}
                                >
                                    <Row gutter={[16, 16]} justify="space-between">
                                        <Col xs={24} md={18} >
                                            <Space split={<Divider type="vertical" />}>
                                                <span>Type: {item.typeName}</span>
                                                <span>Submission Date: {dayjs(item.complaintDate).format('MM/DD/YY')}</span>
                                                <span>Submitted by: {item.createdBy}</span>
                                            </Space>
                                        </Col>
                                        <Col span={6} style={{ textAlign: "end" }}>
                                            <div>{item.dueDate != null ? "Due: " + dayjs(item.dueDate).format('MM/DD/YY') : ''}</div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row gutter={[16, 16]} justify="space-between">
                                        <Col xs={24} md={18} >
                                            <Space>
                                                {item.againstTypeName && <span>Against Type Name: {item.againstTypeName}</span>}
                                                {item.againstTypeName && item.sourceTypeName && <Divider type="vertical" />}
                                                {item.sourceTypeName && <span>Source Type Name: {item.sourceTypeName}</span>}
                                                {item.sourceTypeName && item.memberId && <Divider type="vertical" />}
                                                {item.memberId && <span>Member Id: {item.memberId}</span>}
                                                {item.memberId && item.caseNumber && <Divider type="vertical" />}
                                                {item.caseNumber && <span>Case Number: {item.caseNumber}</span>}
                                            </Space>
                                        </Col>
                                        <Col span={6} style={{ textAlign: "end" }}>
                                            <div>{item.assignedTo != null ? "Assigned to: " + item.assigneeFullName : "Unassigned"}</div>
                                        </Col>
                                    </Row>

                                </Card>
                            </List.Item>
                        }} />
                </Content>

            </Layout>
        </Layout>
    </ConfigProvider>
}