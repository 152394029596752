import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import GrievancePortal, { loader as gpLoader, action as gpAction } from './routes/Grievance/grievancePortal';
import Grievance, { loader as gLoader } from './routes/Grievance/grievance';
import { Authentication } from './Auth/authHOC';
import NotFound from './components/layout/NotFound';
import Search from './routes/Grievance/search/search';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/grievances",
                element: (<GrievancePortal />),
                loader: gpLoader,
                shouldRevalidate: ({ currentUrl, nextUrl }) => {
                    return !nextUrl.pathname.toString().includes("create") || nextUrl.pathname === "/"                // revalidate parent page
                        || currentUrl.pathname !== nextUrl.pathname; // on any path change
                },
               // action: gpAction,
                children: [
                    {
                        path: ":id",
                        element: (<Grievance />),
                        loader: gLoader,
                    },
                    {
                        path: "create",
                        element: (<Grievance type="create" />)
                    }
                ],

            },
            {
                path: "/complaints/search",
                element: (<Search />)
            },
            {
                path: "*",
                element: <NotFound />
            }
        ],
    },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Authentication><RouterProvider router={router} /></Authentication>
    </React.StrictMode>
)
