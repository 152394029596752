import { Outlet, useLoaderData, Form, redirect, useParams, useNavigate } from "react-router-dom";
import { Button, Layout, Input, Space, Menu, ConfigProvider, List, Tag, Flex } from "antd";
import type { MenuProps } from 'antd';
import { useEffect, useRef, useState } from "react";
import { getGrievances } from "../../service/httpsCalls";
import { LoaderSpinner } from "../../components/common/Loader";
import { FilterButton } from "../../components/common/FilterButton";

const { Content, Sider } = Layout;
const { Search } = Input;
type MenuItem = Required<MenuProps>['items'][number];


function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}
export async function action() {
    return redirect(`/grievances/create`);
}
export default function GrievancePortal() {
    const { grievances: initialGrievances, q, loading }: any = useLoaderData();
    const [current, setCurrent] = useState(initialGrievances?.[0]?.id?.toString() ?? "")
    const { id } = useParams();
    const [query, setQuery] = useState(q);
    const [grievancesLoading, setGrievancesLoading] = useState(true)
    const navigate = useNavigate();
    const [grievances, setGrievances] = useState(initialGrievances);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [displayedGrievances, setDisplayedGrievances] = useState([]); // State for currently displayed items
    const listRef = useRef<HTMLDivElement>(null);

    const handleNewGrievanceClick = (e) => {
        e.preventDefault();
        navigate(`/grievances/create${window?.location?.search}`); // Adjust the path as needed
    };
    useEffect(() => {
        // setQuery(q);
        if (id != null) {
            setCurrent(id);
        } else {
            setCurrent([]);
        }

        setGrievancesLoading(loading)

    }, [id, initialGrievances, q]);

    useEffect(() => {
        // When query changes, reset the currentPage to 1
        setCurrentPage(1);
        // Filter grievances based on query
        const filtered = initialGrievances?.filter((grievance: any) => {
            const caseIdMatch = grievance?.clientCaseId?.toLowerCase()?.includes(query?.toLowerCase());
            const statusMatch = grievance?.status?.toLowerCase()?.includes(query?.toLowerCase());
            const tripIdMatch = grievance?.references[0]?.tripId && grievance?.references[0]?.tripId?.toString()?.toLowerCase()?.includes(query?.toLowerCase());
            const memberNameMatch = grievance?.references[0]?.memberName?.toLowerCase()?.includes(query?.toLowerCase());
            return caseIdMatch || statusMatch || tripIdMatch || memberNameMatch;
        });

        setGrievances(filtered);
    }, [query, initialGrievances]);

    useEffect(() => {
        if (listRef.current) {
            const newPageSize = Math.floor(listRef.current.clientHeight / 110);
            setPageSize(newPageSize);
        }

    }, []);

    // This effect updates the list of grievances to display based on the current page
    // useEffect(() => {
    //     const startIndex = (currentPage - 1) * pageSize;
    //     const endIndex = startIndex + pageSize;
    //     const paginatedGrievances = initialGrievances.slice(startIndex, endIndex);
    //     setGrievances(paginatedGrievances);
    // }, [currentPage, initialGrievances, pageSize]);
    useEffect(() => {
        // Pagination effect
        const startIndex = (currentPage - 1) * pageSize;
        const paginatedGrievances = grievances?.slice(startIndex, startIndex + pageSize);
        setDisplayedGrievances(paginatedGrievances);
    }, [currentPage, pageSize, grievances]);
    const handlePageChange = (page: any, pageSize: any) => {
        setCurrentPage(page);
        // Optionally update the page size if it can change
        setPageSize(pageSize);
    };

    return (
        <ConfigProvider theme={{
            components: {
                Layout: { headerBg: "#eff1f5", bodyBg: "#eff1f5" }
            }
        }} >
            <Layout>
                <Sider width="30%" theme="light" style={{ maxHeight: "95vh", position: 'fixed' }}>
                    <Flex vertical={true} ref={listRef}>
                        <Flex justify="space-between" gap="middle" style={{ padding: "1rem" }}>
                            <Form id="search-form" role="search" style={{ width: "100%" }}>
                                <Search
                                    allowClear
                                    id="q"
                                    aria-label="Search grievances"
                                    placeholder="Search"
                                    type="search"
                                    name="q"
                                    defaultValue={q}
                                    value={query}
                                    onChange={(e) => {
                                        setQuery(e.target.value);
                                    }}
                                />
                            </Form>
                            <Form method="post">
                                <Button type="default" onClick={handleNewGrievanceClick}>New</Button>
                            </Form>

                        </Flex>
                        <Space style={{ paddingLeft: "1rem", paddingRight: "1rem", paddingBottom: "1rem", width: "100%" }}>
                            <FilterButton
                                filter="Type"
                                filterItems={["Standard",
                                    "Urgent",
                                    "DMHC"
                                ]}
                            />
                            <FilterButton
                                filter="Status"
                                filterItems={["Open", "Retracted", 'Pending', 'In Progress', 'Internal Review', 'Client Review', 'Resolved', 'Client Denied']}
                            />
                        </Space>
                        {grievancesLoading && <LoaderSpinner size={24} topMargin="10px" />}
                        {grievances?.length ? <List
                            style={{ overflowY: "auto", height: "calc(100vh - 164px)" }}
                            id="grievanceList"
                            dataSource={displayedGrievances}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                total: grievances.length,
                                onChange: handlePageChange,
                                showSizeChanger: true,
                                position: "bottom", align: "center",
                            }}
                            renderItem={(item: any, index) => (

                                <List.Item style={{ padding: "1rem" }}
                                    aria-selected={ window.location.pathname.includes(item.id) }
                                    extra={<Space direction="vertical" align="end">
                                        <Tag style={{ marginRight: 0 }} color="blue">{item.status}</Tag>
                                        <small>{item.dueDate ? item.type + " - " + "Due " + item.dueDate : item.type + "- No Due Date"}</small>
                                    </Space>}
                                    onClick={() => {
                                        if(!window.location.pathname.includes(item.id))
                                            navigate(`${item.id}${window?.location?.search}`)
                                    }                                    }>
                                    <List.Item.Meta
                                        title={item.clientCaseId ?? "Case Id"}
                                        description={item.references[0].memberName + (item.references.length == 1 && item.references[0].tripId != null ? ` - CTC-${item.references[0].tripId}` : ``) + (item.references.length > 1 ? ' - Multiple Trips' : '') }
                                    />
                                </List.Item>
                            )}
                        /> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "calc(100vh - 164px)" }}>No results</div>}
                    </Flex>
                </Sider>
                <Content style={{ marginLeft: "30vw", paddingLeft: ".5rem" }}>
                    <Outlet />
                </Content>
            </Layout>
        </ConfigProvider>
    );
}
export async function loader({ request }: any) {
    const url = new URL(request.url);
    const q = url.searchParams.get("q") || "";
    const type = url.searchParams.getAll("Type") || [];
    const status = url.searchParams.getAll("Status") || [];
    let grievances: any;
    // yield { grievances, q, loading}
    grievances = await getGrievances();
    if (grievances?.length) {
        grievances = grievances?.filter((r: any) => r.references[0].memberName.includes(q));
        if (type.length != 0)
            grievances = grievances?.filter((r: any) => type.includes(r.type));
        if (status.length != 0)
            grievances = grievances?.filter((r: any) => status.includes(r.status));
    }

    let loading = false
    return { grievances, q, loading };
}

